import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'primereact/card';

const SectionCard = ({ icon, title, description }) => (
    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 flex flex-col items-center">
        <div className="flex items-center justify-center bg-sky-900 text-white rounded-full p-4 mb-4">
            <i className={`pi ${icon} text-4xl`} />
        </div>
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-700 dark:text-gray-300">{description}</p>
    </div>
);


SectionCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default SectionCard;
