// src/axiosInstance.js
import axios from "axios";

// Create an instance of axios with default configuration
const axiosInstance = axios.create({
  baseURL: "https://api.streakify.in/", // Replace with your API base URL
  timeout: 10000, // Optional: set a timeout for requests
  headers: {
    "Content-Type": "application/json",
    // Add any other default headers here
  },
});

export default axiosInstance;
