import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axiosInstance from '../Api/axios';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  phone: yup.string().required('WhatsApp number is required'),
  message: yup.string(),
  city: yup.string(),
  inquiry_type: yup.string().default('student')
});

const EnrollForm = ({ onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    setResponseMessage(''); // Reset response message on new submission
    try {
      const response = await axiosInstance.post('/inquiries', data);
      setResponseMessage(response.data.message || 'Your inquiry has been submitted successfully.');
      setIsSubmitted(true);
      setTimeout(onClose, 3000); 
    } catch (error) {
      setResponseMessage('An error occurred. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Adding class to trigger transition when modal is visible
    const modal = document.querySelector('.modal');
    if (modal) {
      modal.classList.add('show');
    }
  }, []);

  return (
    <div className="modal">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className={`form-element ${isLoading ? 'hide' : ''}`}>
            <label className="block text-gray-700 dark:text-gray-300">Name</label>
            <input
              type="text"
              {...register('name')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.name ? 'border-red-500' : ''}`}
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>
          <div className={`form-element ${isLoading ? 'hide' : ''}`}>
            <label className="block text-gray-700 dark:text-gray-300">Email</label>
            <input
              type="email"
              {...register('email')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.email ? 'border-red-500' : ''}`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
          <div className={`form-element ${isLoading ? 'hide' : ''}`}>
            <label className="block text-gray-700 dark:text-gray-300">WhatsApp Number</label>
            <input
              type="text"
              {...register('phone')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.phone ? 'border-red-500' : ''}`}
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
          </div>
          <div className={`form-element ${isLoading ? 'hide' : ''}`}>
            <label className="block text-gray-700 dark:text-gray-300">City</label>
            <input
              {...register('city')}
              className={`w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600 ${errors.city ? 'border-red-500' : ''}`}
            />
            {errors.city && <p className="text-red-500 text-sm">{errors.city.message}</p>}
          </div>
          <div className={`form-element ${isLoading ? 'hide' : ''}`}>
            <label className="block text-gray-700 dark:text-gray-300">Note</label>
            <textarea
              {...register('message')}
              className="w-full px-3 py-2 border rounded-lg dark:bg-slate-700 dark:border-slate-600"
            />
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? <div className="spinner"></div> : 'Submit'}
          </button>
        </form>
      ) : (
        <div className="text-center py-10">
          <h2 className="text-2xl font-bold">Thank You!</h2>
          <p>{responseMessage}</p>
        </div>
      )}
    </div>
  );
};

export default EnrollForm;
