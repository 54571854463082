import React from 'react';
import { Card } from 'primereact/card';

const Privacy = () => {
    return (
        <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
            <Card title="Privacy Policy" className="w-full max-w-4xl">
                <section className="mb-8">
                    <p className="text-base dark:text-gray-300">Last updated: 01 August 2024</p>
                    <p className="text-base dark:text-gray-300">
                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                    </p>
                    <p className="text-base dark:text-gray-300">
                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">Interpretation and Definitions</h2>
                    <h3 className="text-xl font-semibold mb-2 dark:text-gray-200">Interpretation</h3>
                    <p className="text-base dark:text-gray-300">
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </p>
                    <h3 className="text-xl font-semibold mb-2 mt-4 dark:text-gray-200">Definitions</h3>
                    <p className="text-base dark:text-gray-300">For the purposes of this Privacy Policy:</p>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                        <li><strong>Company</strong> (referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to Streakify Technologies Private Limited, info@streakify.in.</li>
                        <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
                        {/* Add other definitions here */}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">Collecting and Using Your Personal Data</h2>
                    <h3 className="text-xl font-semibold mb-2 dark:text-gray-200">Types of Data Collected</h3>
                    <h4 className="text-lg font-semibold mb-2 dark:text-gray-200">Personal Data</h4>
                    <p className="text-base dark:text-gray-300">
                        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                    </p>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Usage Data</li>
                    </ul>
                    {/* Add sections for Usage Data, Tracking Technologies and Cookies, etc. */}
                </section>

                {/* Add more sections as needed */}

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">Contact Us</h2>
                    <p className="text-base dark:text-gray-300">
                        If you have any questions about this Privacy Policy, You can contact us:
                    </p>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>By email: info@sreakify.in</li>
                    </ul>
                </section>
            </Card>
        </div>
    );
};

export default Privacy;