import React, { useEffect, useState } from 'react';
import axiosInstance from '../Api/axios'; // Adjust the path if necessary
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { useParams } from 'react-router-dom';

export default function Status() {
  const { id } = useParams(); // Get the ID from the route parameter
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await axiosInstance.get(`/payments/status/${id}`);
        setStatus(response.data);
      } catch (error) {
        setError('Failed to fetch payment status');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentStatus();
  }, [id]);

  if (loading) return <div className="text-center text-gray-600">Loading...</div>;
  if (error) return <div className="text-center text-red-600">{error}</div>;

  return (
    <div className="bg-gray-50 p-4">
      <div className="max-w-6xl mx-auto px-4 py-10 dark:text-gray-800">
        <h2 className="text-3xl font-bold mb-6 text-center">Payment Status</h2>
        
        <Card className="p-4 shadow-md">
          <h3 className="text-xl font-semibold mb-2">Payment Details</h3>
          <div className="flex items-center mb-2">
            <strong>Transaction ID:</strong>
            <span className="ml-2 text-gray-600">{status?.data?.transactionId}</span>
          </div>
          <div className="flex items-center mb-2">
            <strong>Amount:</strong>
            <span className="ml-2 text-gray-600">₹{(status?.data?.amount / 100).toFixed(2)}</span>
          </div>
          <div className="flex items-center mb-2">
            <strong>Status:</strong>
            <Tag severity={status?.success ? 'success' : 'error'} className="ml-2">
            {status?.message}
            </Tag>
          </div>
          <div className="flex items-center mb-2">
            <strong>Payment Instrument:</strong>
            <span className="ml-2 text-gray-600">{status?.data?.paymentInstrument?.type}</span>
          </div>
          <div className="flex items-center mb-2">
            <strong>Card Type:</strong>
            <span className="ml-2 text-gray-600">{status?.data?.paymentInstrument?.cardType}</span>
          </div>
        </Card>
      </div>
    </div>
  );
}
