import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'primereact/card';

const ServiceCard = ({ title, description, image }) => (
    <Card className="shadow-lg border-0 p-4 bg-white dark:bg-gray-900 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900 rounded-lg">
        <div className="flex items-center justify-center mb-4">
            <img
                src={image}
                alt={title}
                className="w-full h-48 object-cover rounded-lg border border-gray-200 dark:border-gray-700"
            />
        </div>
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-700 dark:text-gray-300">{description}</p>
    </Card>
);

ServiceCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ServiceCard;
