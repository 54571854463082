import React from 'react';
import { Card } from 'primereact/card';

const Tc = () => {
    return (
        <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
            <Card title="Terms and Conditions" className="w-full max-w-4xl">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">1. Introduction</h2>
                    <p className="text-base dark:text-gray-300">
                        Welcome to Streakify Technologies. By accessing or using our website streakify.in and services, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">2. Definitions</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>"Company": Refers to Streakify Technologies.</li>
                        <li>"User": Refers to anyone accessing or using our website and services.</li>
                        <li>"Services": Includes any products, services, or information provided by the Company.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">3. Use of the Website</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Eligibility: You must be at least 18 years old to use our website and services.</li>
                        <li>Account Responsibility: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">4. Intellectual Property</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Ownership: All content on our website, including text, graphics, logos, and software, is the property of Streakify Technologies or its licensors and is protected by copyright and intellectual property laws.</li>
                        <li>Restrictions: You may not reproduce, distribute, or create derivative works from any content on our website without our express written permission.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">5. User Conduct</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Prohibited Activities: You agree not to engage in any activity that is illegal, harmful, or disruptive to the website or its users. This includes, but is not limited to, transmitting harmful software, spamming, or unauthorized access to our systems.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">6. Products and Services</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Availability: All products and services are subject to availability. We reserve the right to modify or discontinue any product or service without prior notice.</li>
                        <li>Pricing: Prices are subject to change without notice. We are not responsible for any errors in pricing.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">7. Payments and Refunds</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Payments: All payments must be made in accordance with the payment methods specified on our website.</li>
                        <li>Refunds: Refund policies are detailed in our Refund Policy section. Please review it for information on eligibility and process.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">8. Links to Third-Party Sites</h2>
                    <p className="text-base dark:text-gray-300">
                        Our website may contain links to third-party websites. We do not endorse or assume any responsibility for the content or practices of these sites. Your use of third-party sites is at your own risk.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">9. Limitation of Liability</h2>
                    <ul className="list-disc pl-5 text-base dark:text-gray-300">
                        <li>Disclaimer: Our website and services are provided "as is" without warranties of any kind. We do not guarantee the accuracy, completeness, or reliability of any content on our website.</li>
                        <li>Liability: To the fullest extent permitted by law, Streakify Technologies shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of our website or services.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">10. Indemnification</h2>
                    <p className="text-base dark:text-gray-300">
                        You agree to indemnify, defend, and hold harmless Streakify Technologies, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses arising out of your use of our website or services.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">11. Termination</h2>
                    <p className="text-base dark:text-gray-300">
                        We reserve the right to terminate or suspend your access to our website and services at our sole discretion, without prior notice, for any reason, including if you violate these Terms and Conditions.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">12. Governing Law</h2>
                    <p className="text-base dark:text-gray-300">
                        These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms and Conditions shall be resolved in the courts of Bangalore, India.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">13. Changes to These Terms</h2>
                    <p className="text-base dark:text-gray-300">
                        We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and your continued use of our website and services constitutes acceptance of the revised Terms.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">14. Contact Us</h2>
                    <p className="text-base dark:text-gray-300">
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <address className="text-base not-italic dark:text-gray-300">
                        Streakify Technologies<br />
                        Bangalore, India-560034<br />
                        <a href="mailto:info@streakify.in" className="text-blue-500 dark:text-blue-400">info@streakify.in</a><br />
                        +91-8105827558
                    </address>
                </section>
            </Card>
        </div>

    );
};

export default Tc;
