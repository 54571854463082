// pages/NotFound.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

const NotFound = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    // Set a timer to update the countdown and redirect after 10 seconds
    const timer = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          navigate('/');
        }
        return prevSeconds - 1;
      });
    }, 1000); // Update every second

    // Clear the interval if the component is unmounted
    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <Card title="404 - Page Not Found" className="">
        <div className="">
          <h1 className="text-6xl font-bold text-red-600 mb-6">Oops!</h1>
          <p className="text-xl mb-6 text-gray-700">
            Sorry, the page you are looking for does not exist.
          </p>
          <div className="mb-6">
            <p className="text-lg text-gray-600 mb-2">
              You will be redirected to the home page in {seconds} seconds...
            </p>
            <ProgressBar value={(10 - seconds) * 10} showValue={false} className="my-4" />
          </div>
          <Button label="Go to Home Now" icon="pi pi-home" className="p-button-primary p-button-lg" onClick={() => navigate('/')} />
        </div>
      </Card>
    </div>
  );
};

export default NotFound;
