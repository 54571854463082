
import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { FaLightbulb, FaBolt, FaHeadset } from 'react-icons/fa';
const Services = () => {

    const handleWhatsAppClick = () => {
        const phoneNumber = "+918105827558"; // Replace with your WhatsApp number
        const message = "Hello, I would like to get more information about your services."; // Replace with your default message
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    // src/data/approachData.js
    const approachData = [
        {
            title: 'Collaboration',
            description: 'We work closely with our clients to ensure their vision is fully understood and realized. Our collaborative approach fosters a strong partnership, allowing us to tailor solutions that meet specific needs and objectives.',
            image: '/assets/img/2.svg' // Ensure the path is correct
        },
        {
            title: 'Understanding Client Needs',
            description: 'We invest time in comprehending the unique challenges and goals of our clients. This deep understanding helps us create solutions that are not only effective but also aligned with their strategic goals.',
            image: '/assets/img/client.svg' // Ensure the path is correct
        },
        {
            title: 'Tailored Solutions',
            description: 'Our solutions are customized to fit the specific requirements of each project. We leverage our expertise to deliver scalable and flexible solutions that grow with our clients\' needs.',
            image: '/assets/img/solutions.svg' // Ensure the path is correct
        }
    ];

    const strategyData = [
        {
            title: 'Technological Excellence',
            description: 'We employ the latest technologies and development methods to deliver high-quality solutions. Our commitment to technological excellence ensures that our applications are both innovative and reliable.',
            image: '/assets/img/tech.jpg' // Ensure the path is correct
        },
        {
            title: 'Efficient Delivery',
            description: 'Our streamlined processes and agile methodologies enable us to deliver projects swiftly and efficiently. We focus on meeting deadlines and exceeding expectations, ensuring timely and successful project completion.',
            image: '/assets/img/hand.jpg' // Ensure the path is correct
        },
        {
            title: 'Global Expertise',
            description: 'Our team’s global expertise allows us to handle projects of varying complexity and scale. We leverage our international knowledge to deploy solutions that are both impactful and scalable across different markets.',
            image: '/assets/img/glob.jpg' // Ensure the path is correct
        }
    ];
    const developmentProcessData = [
        {
            title: 'Planning',
            description: 'Define project goals, requirements, and scope. Create a detailed plan to guide the development process.',
            icon: 'pi pi-calendar' // PrimeIcons class
        },
        {
            title: 'Design',
            description: 'Design the user interface and experience. Focus on creating a visually appealing and functional design.',
            icon: 'pi pi-pencil' // PrimeIcons class
        },
        {
            title: 'Development',
            description: 'Write and test the code. Implement the design and functionality to build the application.',
            icon: 'pi pi-code' // PrimeIcons class
        },
        {
            title: 'Testing',
            description: 'Test the application to ensure it meets quality standards. Identify and fix any issues or bugs.',
            icon: 'pi pi-check-circle' // PrimeIcons class
        },
        {
            title: 'Deployment',
            description: 'Deploy the application to the production environment. Ensure that it is accessible and performs well.',
            icon: 'pi pi-cloud-upload' // PrimeIcons class
        }
    ];
    const services = [
        {
            title: 'Web Development',
            description: 'Create responsive and engaging websites tailored to your business needs.',
            image: '/assets/img/web.svg',
        },
        {
            title: 'Mobile App Development',
            description: 'Build innovative mobile applications for iOS and Android platforms.',
            image: '/assets/img/mobile.svg',
        },
        {
            title: 'Graphic Designing',
            description: 'Create visually appealing designs for your brand and marketing materials.',
            image: '/assets/img/graphic.svg',
        },
        {
            title: 'Software Solutions',
            description: 'Develop customized software solutions tailored to your business requirements.',
            image: '/assets/img/solu.svg',
        },
        {
            title: 'Digital Marketing',
            description: 'Grow your business with strategic digital marketing solutions.',
            image: '/assets/img/digital.svg',
        },
    ];
    const whyChooseStreakify = [
        { title: 'Creative Solutions', description: 'Unlock your potential with innovative and creative strategies designed specifically for your business.', image: '/assets/img/creative1.svg', },
        { title: 'Fast & Reliable Services', description: 'Experience reliable and fast services that drive growth and efficiency in your digital ventures.', image: '/assets/img/fast.svg', },
        { title: '24/7 Dedicated Support', description: 'Access our dedicated support team round-the-clock, ensuring your peace of mind and business continuity.', image: '/assets/img/24.svg', },
    ];

    return (
        <div className="overflow-hidden w-full">
            {/* About Us Section */}
            <div className=" relative bg-cover bg-center w-full h-64" style={{ backgroundImage: "url('/assets/img/standard-quality-control-concept-m.jpg')" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-sky-500 to-blue-500 opacity-25 "></div>
                <div className="relative z-10 flex flex-col justify-center items-center text-center text-white py-16 w-full h-full">
                    <h1 className="text-5xl font-bold mb-4">Services</h1>
                    <p className="text-lg mb-8">Discover why Streakify stands out among the rest.</p>
                </div>
            </div>
            <div className=" w-full min-h-screen bg-gray-100 servicebenefites11-section-area py-16 bg-white dark:bg-gray-900">
                <div className="container mx-auto px-4 md:px-8 lg:px-16">
                    <div className="text-center lg:text-left mb-12">
                        <span className="font-outfit text-lg font-medium text-sky-600 dark:text-gray-300 d-inline-block mb-6">
                            Our Services
                        </span>
                        <h1 className="font-outfit text-4xl font-extrabold text-gray-900 dark:text-gray-100 mb-6">
                            Explore Our Service Offerings
                        </h1>
                        <p className="font-outfit text-lg text-gray-700 dark:text-gray-300 mb-4">
                            Discover our range of services tailored to meet your needs. From web development to digital marketing, our team ensures exceptional results.
                        </p>
                        <Button
                        onClick={handleWhatsAppClick}
                            label="Contact Us"
                            icon="pi pi-phone"
                            className="font-outfit text-lg font-bold p-2 bg-sky-700 text-white hover:bg-sky-800 transition duration-300 ease-in-out"
                            href="#"
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {services.map((service, index) => (
                            <Card
                                key={index}
                                className="p-6 bg-sky-50 text-white shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
                            >
                                <h3 className="text-lg font-bold mb-4 text-sky-700">{service.title}</h3>
                                <p className="text-base mb-4 text-sky-900 dark:text-gray-100">{service.description}</p>
                                {/* Uncomment the line below if you want to include the image */}
                                <img src={service.image} alt={service.title} className="w-40 h-30 object-cover mt-4" />
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
            {/* Our Strategy Section */}
            <section id="our-strategy" className="py-16 bg-white dark:bg-gray-900">
                <div className="container mx-auto px-4 md:px-8 lg:px-16">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-6 text-center">
                        Our <span className="text-3xl font-bold text-sky-700 dark:text-white">Strategy</span>
                    </h2>
                    <p className="text-lg text-gray-700 dark:text-gray-300 mb-12 text-center">
                        Our strategy ensures excellence and efficiency by leveraging advanced technology and global expertise. Here’s how we do it:

                    </p>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {strategyData.map((item, index) => (
                            <Card key={index} className="bg-white dark:bg-gray-700 shadow-lg p-4 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:scale-105 hover:border hover:border-blue-500 rounded-lg">
                                <img src={item.image} alt={item.title} className="w-full h-42 object-cover mb-4 rounded-lg" />
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4 text-center">{item.title}</h3>
                                <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>



            <section className="py-16 px-4 bg-gray-100 dark:bg-gray-800">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-3xl font-bold mb-8 text-slate-900 dark:text-white">Why Choose <span className="text-3xl font-bold text-sky-700 dark:text-white" >Streakify ? </span></h2>
                    <p className="text-lg mb-12 text-gray-700 dark:text-gray-300">
                        At Streakify, we offer exceptional services tailored to your needs. Our commitment to excellence sets us apart.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {whyChooseStreakify.map((reason, index) => (
                            <div
                                key={index}
                                className="p-6 bg-white shadow rounded-lg text-center dark:bg-gray-900 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900"
                            >
                                <div className="flex items-center justify-center mb-4">
                                    <img src={reason.image} alt={reason.title} className="w-30 h-20 text-sky-700" />
                                </div>
                                <h3 className="text-1xl font-semibold mb-2 text-slate-900 dark:text-white">
                                    {reason.title}
                                </h3>
                                <p className="text-gray-600 dark:text-gray-300">{reason.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Our Development Process Section */}
            <section id="our-development-process" className="min-h-screen w-full py-16 bg-white dark:bg-slate-800">
                <div className="container mx-auto px-4 md:px-8 lg:px-16">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-6 text-center">
                        Our <span className="text-3xl font-bold text-sky-700 dark:text-white">Development Process</span>
                    </h2>
                    <p className="text-lg text-gray-700 dark:text-gray-300 mb-12 text-center">
                        Our development process is structured to ensure quality and efficiency at every stage. Here’s how we approach each phase:
                    </p>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {developmentProcessData.map((item, index) => (
                            <div
                                key={index}
                                className="bg-white dark:bg-gray-700 shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:scale-105 hover:border hover:border-blue-500 rounded-lg"
                            >
                                <div className="flex items-center justify-center mb-4">
                                    <i className={`pi ${item.icon} text-4xl text-sky-600`}></i>
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4 text-center">{item.title}</h3>
                                <p className="text-gray-700 dark:text-gray-300 text-center">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>



            {/* Our Approach Section */}
            <section id="our-approach" className="py-16 bg-gray-100 dark:bg-gray-800">
                <div className="container mx-auto px-4 md:px-8 lg:px-16">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-6 text-center">
                        Our <span className="text-3xl font-bold text-sky-700 dark:text-white">Approach</span>
                    </h2>
                    <p className="text-lg text-gray-700 dark:text-gray-300 mb-12 text-center">
                        At Streakify Technologies, we achieve exceptional results by collaborating closely with our clients and providing tailored solutions.

                    </p>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {approachData.map((item, index) => (
                            <div key={index} className="bg-white dark:bg-gray-700 shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:scale-105 hover:border hover:border-blue-500">
                                <div className='flex items-center justify-center mb-4'>
                                    <img src={item.image} alt={item.title} className="w-40 h-30  mb-4 rounded " />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4 text-center">{item.title}</h3>
                                <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>




        </div>

    );
};

export default Services;
