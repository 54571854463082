import React, { useState } from 'react';
import { Card } from 'primereact/card';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import axiosInstance from '../Api/axios';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        city: '',
        message: '',
        inquiry_type: 'client'
    });
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setResponseMessage('');

        try {
            const response = await axiosInstance.post('/inquiries', formData);
            setResponseMessage(response.data.message);
            setFormData({
                name: '',
                email: '',
                phone: '',
                city: '',
                message: '',
                inquiry_type: 'client'
            });
        } catch (err) {
            setError(err.response?.data?.error || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="overflow-hidden w-full">
            {/* Header Section */}
            <div className="relative bg-cover bg-center w-full h-[300px] lg:h-[300px]" style={{ backgroundImage: "url('/assets/img/ll.jpg')" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-sky-500 to-blue-500 opacity-35"></div>
                <div className="relative z-10 flex flex-col justify-center items-center text-center text-white py-16 w-full h-full">
                    <h1 className="text-4xl lg:text-5xl font-bold mb-4">Contact Us</h1>
                    <p className="text-sm lg:text-lg mb-8">Contact Us with Any Queries.</p>
                </div>
            </div>

            {/* Contact Info Section */}
            <div className="contactinner-section-area py-10 lg:py-20 bg-gray-100 dark:bg-slate-900 dark:text-white">
                <div className="container mx-auto px-4 py-12">
                    <div className="flex flex-col items-center text-center mb-8">
                        <div className="w-full lg:w-2/3">
                            <div className="contact5-textarea5">
                                <span className="font-outfit text-lg font-medium text-sky-600 dark:text-sky-300 inline-block mb-6">
                                    Contact Us
                                </span>
                                <h1 className="text-3xl lg:text-4xl font-bold mb-3 lg:mb-5">
                                    Contact Us for Expert Solutions and Innovative Courses.
                                </h1>
                                <p className="text-base lg:text-lg mb-3 lg:mb-5">
                                    At Streakify Technologies, we offer expert support to help you leverage the latest in technology solutions. Whether you need assistance with our products, have questions about our services, or require technical support, our dedicated team is here to help.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center gap-6">
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/call.svg" alt="Phone Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Call Us</p>
                                <a href="tel:+91-8105827558" className="text-xl font-bold">+91-8105827558</a>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/location.svg" alt="Location Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Visit Us</p>
                                <span className="text-xl font-bold">Bangalore, India-560034</span>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/mail1.svg" alt="Email Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Mail Us</p>
                                <a href="mailto:info@streakify.in" className="text-xl font-bold">info@streakify.in</a>
                            </div>
                        </Card>
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/chat.svg" alt="Chat Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Live Chat</p>
                                <span className="text-xl font-bold">Chat with Us 24/7</span>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Office Locations Section */}
            <div className="w-full bg-gray-100 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-center">Office Locations</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-6">
                            <h2 className="text-lg font-semibold mb-2">Our Headquarters</h2>
                            <p className="text-sm dark:text-gray-300">
                                1st floor, SPD Plaza, 52, Jyoti Niwas College Rd,<br />
                                Koramangala Industrial Layout, Koramangala,<br />
                                Bengaluru, Karnataka - 560034<br />
                                Phone: +91-8105827558<br />
                                Email: info@streakify.in
                            </p>
                            <div className="mt-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.593650884204!2d77.61340807454606!3d12.933818215706554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1451d17a18a7%3A0xadc0e7c2d98ab83b!2sStreakify.io!5e0!3m2!1sen!2sin!4v1722089061502!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-6">
                            <h2 className="text-lg font-semibold mb-2">Our Hyderabad Office</h2>
                            <p className="text-sm dark:text-gray-300">
                                Flat No : G3, MJR Mansion, BJP Office Rd, <br />
                                Shanthi Nagar,
                                Kukatpally, Hyderabad, Telangana 500072<br />
                                Phone: +91-8105827558<br />
                                Email: info@streakify.in
                            </p>
                            <div className="mt-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.470443922777!2d78.4118614746291!3d17.485042299985594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb92a0b1e78c6f%3A0xd507fba48b9ff6b2!2sStreakify%20Technologies!5e0!3m2!1sen!2sin!4v1722088932843!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Form Section */}
            <div className="w-full py-12 bg-white dark:bg-slate-800">
    <div className="container mx-auto px-4">
        <div className="flex flex-col items-center text-center mb-8">
            <h2 className="text-2xl lg:text-3xl font-bold mb-4">Get in Touch</h2>
            <p className="text-base lg:text-lg mb-6">
                Fill out the form below to send us a message. We'll get back to you as soon as possible.
            </p>
        </div>
        <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="form-group">
                    <label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        aria-label="Name"
                        aria-required="true"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        aria-label="Email"
                        aria-required="true"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="block text-sm font-medium mb-2">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        aria-label="Phone"
                        aria-required="true"
                        pattern="[0-9]{10}" 
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city" className="block text-sm font-medium mb-2">City</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                        aria-label="City"
                        aria-required="true"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    />
                </div>
                <div className="form-group col-span-2">
                    <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        required
                        aria-label="Message"
                        aria-required="true"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    ></textarea>
                </div>
                <div className="form-group col-span-2">
                    <label htmlFor="inquiry_type" className="block text-sm font-medium mb-2">Inquiry Type</label>
                    <select
                        id="inquiry_type"
                        name="inquiry_type"
                        value={formData.inquiry_type}
                        onChange={handleChange}
                        required
                        aria-label="Inquiry Type"
                        aria-required="true"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"
                    >
                        <option value="client">Client</option>
                        <option value="partner">Partner</option>
                    </select>
                </div>
            </div>
            <div className="text-center mt-6">
                <button
                    type="submit"
                    disabled={loading}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    {loading ? 'Sending...' : 'Send Message'}
                </button>
            </div>
            {responseMessage && (
                <div className="mt-6 text-center text-green-500">
                    {responseMessage}
                </div>
            )}
            {error && (
                <div className="mt-6 text-center text-red-500">
                    {error}
                </div>
            )}
        </form>
    </div>
</div>

        </div>
    );
};

export default Contact;
