import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axiosInstance from '../Api/axios';

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  
  const [countryCode, setCountryCode] = useState('+91');

  useEffect(() => {
    if (!location.state?.course) {
      navigate('/', { replace: true });
    } else {
      setCourse(location.state.course);
    }
  }, [location.state, navigate]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const fullPhoneNumber = `${countryCode}${data.phone}`;
      const response = await axiosInstance.post('/payments', {
        name: data.name,
        phone: fullPhoneNumber,
        amount: course.discountedPrice,
      });

      if (response.data) {
        window.location.href = response.data;
      } else {
        console.log('No payment URL received');
      }
    } catch (error) {
      console.log('Payment error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!course) return null;

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center relative">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl max-w-md w-full space-y-8">
        <div>
          <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-gray-100">
            Payment for {course.title}
          </h2>
          <img
            src={course.image}
            alt={course.title}
            className="w-full h-56 object-cover rounded-lg mt-6 mb-4"
          />
          <p className="mt-2 text-center text-gray-600 dark:text-gray-400">{course.description}</p>
          <div className="mt-4 text-center">
            <span className="text-lg text-gray-500 dark:text-gray-400 line-through">
              ₹{course.originalPrice}
            </span>
            <span className="text-2xl text-cyan-600 dark:text-cyan-400 font-bold ml-2">
              ₹{course.discountedPrice}
            </span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="name" className="sr-only">Full Name</label>
              <input
                id="name"
                type="text"
                {...register('name', { required: 'Full Name is required' })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Full Name"
              />
            </div>
            <div className="flex">
              <div className="w-1/3">
                <label htmlFor="countryCode" className="sr-only">Country Code</label>
                <input
                  id="countryCode"
                  type="text"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="+91"
                />
              </div>
              <div className="w-2/3">
                <label htmlFor="phone" className="sr-only">Phone Number</label>
                <input
                  id="phone"
                  type="tel"
                  {...register('phone', { required: 'Phone Number is required' })}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          {(errors.name || errors.phone) && (
            <div className="text-red-500 text-sm mt-2">
              {errors.name?.message || errors.phone?.message}
            </div>
          )}
          <div>
            <button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : null}
              {loading ? 'Processing...' : 'Complete Purchase'}
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="absolute inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl max-w-md w-full text-center">
            <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">Processing Payment</h2>
            <div className="flex flex-col items-center justify-center">
              <svg className="w-16 h-16 animate-spin text-cyan-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0 1 16 0H4z"></path>
              </svg>
              <p className="mt-6 text-lg text-gray-600 dark:text-gray-400">Please wait while we process your payment.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}